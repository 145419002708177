.container
  padding: 8px !important
  border: 1px solid #002747
  border-radius: 4px

.title
  font-family: var(--poppins)
  color: #002747
  font-size: 18px

.text
  font-family: var(--poppins)
  color: #002747

.button
  background-color: #9b722a !important
  padding: 8px 16px !important
  border-radius: 8px !important
  color:#ffffff !important
  font-family: var(--poppins) !important
