$goldTMB: #9b722a
$blueTMB: #002747

.paginator
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  align-items: center
  padding: 10px 20px
  .pageNum
    margin: 5px
    color: $blueTMB
    cursor: pointer
    &.selected
      color: $goldTMB

.arrow
  font-size: 18px
  font-weight: bold
  cursor: pointer
  margin: 0px 10px

.last
  cursor: pointer
