.container
  min-height: 100vh
  background-color: #f1f1f1
  display: flex
  justify-content: center
  padding-top: 60px

.logoContainer
  display: flex
  justify-content: center

.logo
  width: 140px
  margin-bottom: 12px

.card
  padding: 16px
  background-color: #ffffff
  border-radius: 8px
  box-shadow: 0px 2px 6px 0 rgba(20,20,43,.06)
  width: 360px

.cardTitle
  color: #000
  font-weight: 600
  font-size: 18px
  margin-bottom: 12px

.cardText
  color: var(--color-gray)

.inputContainer
  margin-top: 16px

.inputLabel
  color: var(--primary-colorblue)

.input
  font-size: 16px
  padding: 12px
  box-sizing: border-box
  -webkit-tap-highlight-color: 'transparent'
  font-weight: 'normal'
  width: 100%
  background-color: transparent
  line-height: 1.2
  touch-action: 'manipulation'
  border: 1px solid var(--primary-colorgold)
  outline: 'none'
  border-radius: 8px
  appearance: 'none'

.button
  font-size: 30px
  width: 100%
  background-color: #002747 !important
  border-radius: 8px !important
  margin-top: 16px !important
