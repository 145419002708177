$blueTMB: #002747

#Search
  box-sizing: border-box
  padding: 10px
  &.phone
    .inputsContainer
      flex-direction: column
      padding-bottom: 15px

.inputsContainer
  display: flex
  align-items: center

.dateContent
  padding-bottom: 10px

.inputContent
  width: 100%
  box-sizing: border-box
  padding: 5px

.buttonsContainer
  display: flex

.button
  margin-right: 10px !important
  background-color: $blueTMB !important
  &.clean
    background-color: #eeeeee !important
    color: #000000 !important
  &.excel
    background-color: #7cb342 !important
    color: #000000 !important
