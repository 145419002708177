.container
  padding: 16px

.title
  font-size: 18px
  font-weight: 600

.subtitle
  color: var( --primary-text-gray)
  padding-bottom: 16px

.row
  display: flex
  column-gap: 16px

.inputContainer
  flex: 1
  margin: 8px 0px
  width: 360px

.inputTitle
  color: var(--primary-colorblue)
  padding-left: 4px

.input
  box-sizing: border-box
  width: 100%
  height: 40px
  padding: 8px 16px
  border-radius: 8px
  border: 1px solid var(--primary-colorgold)
  font-size: 16px

.inputfile
  width: 350px
  max-width: 100%
  color: #444
  padding: 5px
  background: #fff
  border-radius: 10px
  border: 1px solid var(--primary-colorgold)

.inputfile::file-selector-button
  margin-right: 20px
  border: none
  background: var(--primary-colorblue)
  padding: 10px 20px
  border-radius: 10px
  color: #fff
  cursor: pointer
  transition: background .2s ease-in-out
  &:hover
    background: var(--primary-colorgold)

.button
  margin-top: 16px
  color: var(--white)
  background-color: var(--primary-colorblue)
  border-radius: 8px
  display: flex
  align-items: center
  justify-content: center
  width: 200px
  padding: 8px
  font-weight: 500
  cursor: pointer
  &:hover
    background-color: var(--primary-colorgold)

.circularProgress
  color: var(--white) !important
  width: 24px !important
  height: 24px !important

.responseButtons
  display: flex
  column-gap: 18px

.downloadButton
  background-color: var(--white)
  border: 1px solid green
  border-radius: 4px
  padding: 8px 16px
  cursor: pointer
  color: green
  &:hover
    background-color: #eee

.downloadTXT
  border: 1px solid blue
  color: blue
