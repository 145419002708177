$blueTMB: #002747

.productListContainer
  box-sizing: border-box
  padding: 10px

.productRow
  display: flex
  justify-content: space-between
  padding: 15px
  box-sizing: border-box
  &:hover
    text-decoration: underline
  // &.1
  //   background-color: #eeeeee
  &.header
    border-bottom: 1px solid #eeeeee
    &:hover
      text-decoration: none

.productColumn
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.imageProduct
  width: 300px !important
  height: 50px
  text-align: center
  img
    height: 100%

.productFormContainer
  padding: 10px

.inputsContainer
  display: flex
  flex-wrap: nowrap
  margin-top: 20px
  &.kitPatro
    margin-top: 60px
  &.last
    margin-bottom: 60px

.three
  width: 33.33%
  padding-left: 15px
  &.first
    padding-left: 0px

.four
  width: 25%
  padding-left: 15px
  &.first
    padding-left: 0px

.six
  width: 16.66%
  padding-left: 15px
  box-sizing: border-box
  &.first
    padding-left: 0px

.attributeName
  font-weight: 600
  font-size: 20px
  padding: 20px 0px 10px

.buttonsContainer
  margin-top: 50px

.button
  font-size: 30px
  width: 200px
  background-color: $blueTMB !important

.actions
  display: flex
  justify-content: center
  align-items: center

.action
  color: green
  cursor: pointer
  margin-left: 10px
  &.delete
    color: red

.edit
  color: green !important

.headerColumn
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  font-weight: 600

.headerActions
  display: flex
  justify-content: center
  align-items: center
  font-weight: 600

.tabs
  display: flex
  box-sizing: border-box

.tab
  padding: 10px

.tabContent
  padding: 8px
  border-left: 1px solid $blueTMB
  border-top: 1px solid $blueTMB
  border-right: 1px solid $blueTMB
  cursor: pointer
  border-radius: 5px 5px 0px 0px
  &.true
    background-color: #eeeeee
  &:hover
    background-color: #eeeeee

.loading
  display: flex
  box-sizing: border-box
  padding: 20px
  justify-content: center

.link
  color: #039be5
  cursor: pointer

.buttonNewProduct
  margin: 10px 0px

.inputText
  margin: 0px
  width: 100%
  box-sizing: border-box
  &label.Mui-focused
    color: 'green'
