.container
  padding: 20px
  background: #f7f7f7
  border-radius: 20px
  text-align: center
  margin: 30px

.question
  color: #758893
  padding: 16px
  font-size: 18px
  font-weight: 600

.instructions
  color: #758893
  padding: 16px
  margin-top: -10px
  font-size: 13px

.titleInstructions
  color: #758893
  font-weight: 700

.concept
  color: #758893
  padding: 16px
  text-align: center
  font-weight: 700

.button
  width: 60%
  background-color: #002747 !important
  color:#ffffff !important
  font-weight: 600 !important
  border-radius: 30px
  text-transform: capitalize
  font-size: 16px
  &:hover
    background-color: #9b722a!important
  &.true
    background-color: #959495 !important

.buttonContainer
  margin-top: 20px
  display: flex
  justify-content: space-between

.buttonContainerAnt
  width: 40%
  display: flex
  justify-content: flex-end
  padding-left: 15px
  margin-bottom: 20px

.buttonContainerSig
  width: 40%
  display: flex
  justify-content: flex-start
  padding-left: 5px
  margin-bottom: 20px

.group
  color: #9b722a
  font-size: 18px
  font-weight: 600
  text-align: center
  margin-top: 30px

.options
  display: flex
  justify-content: center
  align-items: center
  &.phone 
    display: flex 
    flex-direction: column

.twoOptions
  display: flex
  justify-content: center
  align-items: center
  &.phone
    display: flex
    justify-content: center
    align-items: center

.option
  color: #758893
  padding: 20px
  cursor: pointer

.textArea
  font-family: var(--poppins)
  border: 1px solid #758893
  border-radius: 8px
  padding: 8px
  padding-left: 16px
  width: 100%
  min-height: 150px
  resize: none

.inputText
  font-family: var(--poppins)
  border: 1px solid #758893
  border-radius: 8px
  height: 48px
  font-size: 14px
  width: 100%
  padding: 8px
  padding-left: 16px
  &:focus
    outline: none !important
    border: 1px solid #9b722a

.optionsInputs
  width: 100%
  margin-top: 10px

.separator
  margin-top: 10px

.finish
  text-align: center 
  padding-top: 20px
  font-family: var(--poppins)
  color: #758893
  font-weight: 600
  font-size: 20px
  padding-bottom: 20px

.iconsRadio
  cursor: pointer
  margin-top: 4px
  margin-left: 5px

.iconsRadioChecked
  color: #9b722a !important

.count
  padding: 0px
  text-align: right
  margin: 30px
  color: #758893

.circularProgress
  color: #002747 !important

.area
  max-width: 820px
  margin: 0px auto
  margin-top: 20px

.comment
  width: 100%
  height: auto

.comment-text-area 
  float: left
  width: 100%
  height: auto

.closeSesion
  padding: 16px
  width: 100%
  text-align: right
  font-weight: 600 
  cursor: pointer
  &:hover
    text-decoration: underline

.info
  padding-left: 35px

.label
  color: #002747
  font-weight: 600
