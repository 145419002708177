#Contracts
  &.phone
    .width50, .buttonContainer, .verifyButton
      width: 100%
    .actionContainer, .verify
      flex-wrap: wrap
    .verifyText, .legalText
      margin-top: 8px
      padding-left: 0px
    .agreeAdvice
      margin-top: 16px

.contractMainContainer
  padding: 16px

.contractContainer
  background-color: var(--primary-background)
  border-radius: 8px
  width: 100%
  max-width: 1000px
  margin: 0 auto
  padding: 16px 8px
  font-family: var(--poppins)

.areaContainer
  display: flex
  flex-wrap: wrap
  width: 100%
  margin-bottom: 24px
  box-sizing: border-box

.inputContainer
  padding: 8px
  position: relative
  box-sizing: border-box

.width50
  width: 50%

.width33
  width: 33.33%

.width100
  width: 100%

.inputText
  font-family: var(--poppins)
  font-weight: 300
  color: var(--primary-text-gray)
  border: 1px solid var(--primary-text-gray)
  border-radius: 8px
  height: 50px
  font-size: 16px
  width: 100%
  padding: 8px
  padding-left: 16px
  box-sizing: border-box
  &:focus
    outline: none !important
    border: 1px solid var(--primary-colorgold)
  &.true
    border: 1px solid var(--red-alert)

.errorText
  color: var(--red-alert)
  font-size: 12px

.areaName
  display: flex
  align-items: center
  font-weight: 500
  color: var(--primary-colorblue)
  padding: 8px

.titleIcon
  color: var(--primary-colorgold)
  padding-right: 8px

.buttonContainer
  width: 200px

.verifyButton
  width: 220px

.button
  width: 100%
  background-color: var(--primary-colorblue) !important
  color: var(--white) !important
  font-weight: 600 !important
  &:hover
    background-color: var(--primary-colorgold) !important
  &.true
    background-color: var(--color-disabled) !important

.actionContainer
  margin-top: 32px
  padding-left: 8px
  display: flex

.agreeContainer
  display: flex
  align-items: center
  font-size: 12px
  color: var(--primary-text-gray)
  padding-left: 16px

.check
  color: var(--primary-colorgold) !important
  padding-top: 0px !important
  padding-bottom: 2px !important

.circularProgress
  color: var(--white) !important
  width: 25px !important
  height: 25px!important

.floatingLabel
  position: absolute
  top: 9px
  left: 9px
  height: 30px
  width: 80%
  background: var(--white)
  display: flex
  align-items: center
  padding: 8px
  padding-left: 16px
  border-radius: 8px
  font-family: var(--poppins)
  font-weight: 300
  color: var(--primary-text-gray)

.finalInputs
  display: flex
  flex-wrap: wrap

.verify
  padding: 8px
  display: flex
  align-items: center
  flex: 1

.verifyText
  padding-left: 8px
  color: var(--color-invalid)
  display: flex
  align-items: center
  &.true
    color: var(--chart-line-green)

.validateIcon
  padding-right: 4px
  font-size: 24px

.showPassword
  position: absolute
  right: 16px
  top: 22px
  color: var(--primary-text-gray)

.showPasswordIcon
  cursor: pointer

.instructionsAlert
  padding: 4px

.instructions
  display: flex
  align-items: center
  padding: 8px
  color: var(--primary-colorblue)

.instructionsTitle
  padding-right: 8px

.instructionsList
  margin: 0px
  padding: 4px 0px 0px 16px

.tooltip
  cursor: pointer

.legalLink
  color: var(--primary-colorgold)
  cursor: pointer
  &:hover
    text-decoration: underline

.sponsorName
  width: 100%
  padding: 0px 8px
  color: var(--primary-colorblue)
  font-weight: 500

.sponsorProgress
  color: var(--primary-colorblue) !important
  width: 20px !important
  height: 20px!important

.legalText
  display: flex
  flex-wrap: wrap

.agreeTitle
  font-size: 14px
  color: var(--primary-colorblue)
  padding-left: 24px
  padding-bottom: 4px

.legalModal
  padding: 0px 16px 16px

.agreeAdvice
  color: var(--primary-text-gray)
  font-size: 12px
  display: flex
  align-items: center
  padding-left: 16px

.checkboxContainer
  width: 100%
  flex-direction: row
  align-items: center
