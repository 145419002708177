.container
  padding: 16px

.secondaryMenu
  display: flex
  column-gap: 24px
  a
    color: var(--link-color)
    text-decoration: none
    &:hover
      text-decoration: underline

.tableContainer
  margin-top: 24px

.notiTable
  border-collapse: collapse
  width: 100%
  max-width: 1200px
  margin: 0 auto

.notiRow
  display: flex
  border-bottom: 1px var(--coloer-border-gray-lite) solid

.notiColumn
  padding: 8px
  i
    color: green
    cursor: pointer

.centered
  text-align: center

.rowHeader
  color: var(--white)
  background-color: var(--primary-colorblue)
  border-radius: 8px 8px 0px 0px
  display: flex
