$blueTMB: #002747
$blueLink: #039be5

.productListContainer
  box-sizing: border-box
  padding: 10px

.catList
  margin-bottom: 15px

.selectList
  padding: 20px 0px 10px
  display: flex

.optionList
  color: $blueLink
  margin-right: 20px
  cursor: pointer
  &:hover
    text-decoration: underline
  &.true
    text-decoration: underline

.productRow
  display: flex
  justify-content: space-between
  padding: 15px
  box-sizing: border-box
  &:hover
    text-decoration: underline
    background-color: #eeeeee
  &.header
    border-bottom: 1px solid #eeeeee
    &:hover
      text-decoration: none

.productColumn
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.imageProduct
  width: 300px !important
  height: 50px
  text-align: center
  img
    height: 100%

.productFormContainer
  padding: 10px

.inputsContainer
  display: flex
  flex-wrap: nowrap
  margin-top: 20px
  &.kitPatro
    margin-top: 60px
  &.last
    margin-bottom: 60px

.innerInputsContainer
  display: flex
  flex-wrap: nowrap

.three
  width: 33.33%
  padding-left: 15px
  &.first
    padding-left: 0px

.four
  width: 25%
  padding-left: 15px
  &.first
    padding-left: 0px

.five
  width: 20%
  padding-left: 15px
  &.first
    padding-left: 0px

.six
  width: 16.66%
  padding-left: 15px
  box-sizing: border-box
  &.first
    padding-left: 0px

.attributeName
  font-weight: 600
  font-size: 20px
  padding: 20px 0px 10px

.buttonsContainer
  margin-top: 50px

.button
  font-size: 30px
  width: 200px
  background-color: $blueTMB !important

.actions
  display: flex
  justify-content: center
  align-items: center
  

.action
  color: green
  cursor: pointer
  margin-left: 10px
  &.delete
    color: red

.edit
  color: green !important

.headerColumn
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  font-weight: 600

.headerActions
  display: flex
  justify-content: center
  align-items: center
  font-weight: 600

.tabs
  display: flex
  box-sizing: border-box

.tab
  padding: 10px

.tabContent
  box-sizing: border-box
  height: 100%
  padding: 8px
  border-left: 1px solid $blueTMB
  border-top: 1px solid $blueTMB
  border-right: 1px solid $blueTMB
  cursor: pointer
  border-radius: 5px 5px 0px 0px
  font-size: 14px
  &.true
    background-color: $blueTMB
    color: #ffffff
  &:hover
    background-color: #eeeeee
    &.true
      background-color: $blueTMB
      color: #ffffff

.loading
  display: flex
  box-sizing: border-box
  padding: 20px
  justify-content: center

.link
  color: #039be5
  cursor: pointer

.newCatalogButton
  padding-left: 15px
  display: flex

.buttonNewProduct
  margin: 10px 0px

.inputText
  margin: 0px
  width: 100%
  box-sizing: border-box
  &label.Mui-focused
    color: 'green'

.subcatContainer
    display: flex

.subCat
    padding: 10px
    span
        color: $blueLink
        cursor: pointer
        &:hover
            text-decoration: underline
    &.true
      border: 2px solid #039be5

.warning
  background-color: #ffd95a
  color: #000000
  margin-top: 15px
  width: 100%
  display: flex
  padding: 20px
  box-sizing: border-box
  border-radius: 5px
  border: 1px solid #f9a825

.catDate
  margin-top: 15px
  .text
    font-weight: 600
  span
    padding-right: 5px

.title
  font-weight: 600
  font-size: 20px
  margin-top: 15px
