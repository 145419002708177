$blueTMB: #002747
$blueLightTMB: #344f73
$blueDarkTMB: #000020
$goldTMB: #9b722a
$gray: #eaeff1

.phone
  .header
    position: sticky
    top: 0
    z-index: 2
  .mainContent
    padding: 20px 15px

.header
  display: flex
  color: #ffffff
  background-color: $goldTMB
  height: 55px
  box-sizing: border-box
  align-items: center
  padding: 0px 20px
  i
    font-size: 2rem
    margin-right: 20px
    cursor: pointer

.sectionName
  font-size: 1.2rem

.content
  display: flex
  box-sizing: border-box
  background-color: $gray
  height: auto
  min-height: 100vh

.main
  width: 100%

.mainContent
  box-sizing: border-box
  padding: 48px 36px 48px

.card
  box-sizing: border-box
  width: 100%
  background-color: #ffffff
  margin: 0 auto
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)
  border-radius: 8px

.please
  box-sizing: border-box
  padding: 20px
