.tableRow
  box-sizing: border-box
  width: 100%
  display: flex
  flex-wrap: nowrap
  cursor: pointer
  &:hover
    background-color: rgb(245, 245, 245)

.rowItem
  flex: 1
  box-sizing: border-box
  padding: 15px 24px
  font-size: 13px
  border-bottom: 1px solid rgb(224, 224, 224)
