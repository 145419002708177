.onoffswitch
  position: relative
  width: 131px
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none

.onoffswitchcheckbox
  display: none

.onoffswitchlabel
  display: block
  overflow: hidden
  cursor: pointer
  border: 2px solid #e4e1e1
  border-radius: 50px

.onoffswitchinner
  width: 200%
  margin-left: -100%
  -moz-transition: margin 0.3s ease-in 0s
  -webkit-transition: margin 0.3s ease-in 0s
  -o-transition: margin 0.3s ease-in 0s
  transition: margin 0.3s ease-in 0s

.onoffswitchinner:before, .onoffswitchinner:after
  float: left
  width: 50%
  height: 41px
  padding: 0
  line-height: 41px
  font-size: 25px
  color: white
  font-family: Trebuchet, Arial, sans-serif
  font-weight: bold
  -moz-box-sizing: border-box
  -webkit-box-sizing: border-box
  box-sizing: border-box

.onoffswitchinner:before
  content: "DESACTIVAR"
  padding-left: 16px
  background-color: #f5f1f1
  color: #9b722a
  font-size: 10px

.onoffswitchinner:after
  content: "ACTIVAR"
  padding-right: 20px
  background-color: #EEEEEE
  color: #999999
  text-align: right
  font-size: 10px

.onoffswitchswitch
  width: 38px
  margin: 1.5px
  background: #A1A1A1
  border: 2px solid #FFFFFF
  border-radius: 50px
  position: absolute
  top: 0
  bottom: 0
  right: 83px
  -moz-transition: all 0.3s ease-in 0s
  -webkit-transition: all 0.3s ease-in 0s
  -o-transition: all 0.3s ease-in 0s
  transition: all 0.3s ease-in 0s

.onoffswitchcheckbox:checked + .onoffswitchlabel .onoffswitchinner
  margin-left: 0

.onoffswitchcheckbox:checked + .onoffswitchlabel .onoffswitchswitch
  right: 0px
  background-color: #9b722a

.title
  font-size: 20px
  font-weight: 600
  padding: 15px

.form
  margin-top: 15px
  margin-left: 15px
  margin-bottom: 10px

.message
  font-size: 14px
  font-weight: 400
  padding: 15px

.progress
  margin-left: 10px
  margin-right: 10px
