.wellcome
  width: 100%
  max-width: 1000px
  margin: 0 auto

.goldTitle
  color: var(--primary-colorgold)
  font-family: var(--title-font)
  font-size: 32px
  font-weight: 500
  text-align: center

.blueSubtitle
  color: var(--primary-colorblue)
  font-family: var(--subtitle-font)
  font-size: 30px
  font-weight: 500
  text-align: center

.titleWelcome
  color: var(--secondary-colordarkblue)
  font-family: var(--poppins)
  margin-top: 15px
  margin-bottom: 30px
  text-align: center
  padding-left: 40px
  padding-right: 40px

.blackLabel
  font-weight: 700
  text-align: center
  color: var(--secondary-colordarkblue)
  font-family: var(--poppins)

.textSuccess
  text-align: center
  color: var(--secondary-colordarkblue)
  font-family: var(--poppins)

.verify
  display: flex !important
  align-items: center !important
  text-align: center

.validateSMS
  text-align: center
  color: var(--primary-colorgold)
  font-family: var(--poppins)
  font-size: 16px
  font-weight: 700
  padding-top: 30px
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  margin: 0 auto
  label
    cursor: pointer
    padding-left: 8px

.numKit
  color: var(--primary-colorblue) !important
  text-align: center

.labelGold
  color: var(--primary-colorgold)
  font-weight: 700
  font-size: 16px

.paddingLeft
  padding-left: 8px

.finishButton
  text-align: center

.buttonFinish
  width: 30%
  height: 50px
  background-color: var(--primary-colorblue) !important
  color: var(--white) !important
  font-weight: 600 !important
  border-radius: 8px !important
  margin-top: 10px !important
  margin-left: 5px !important
  &:hover
    background-color: var(--primary-colorgold) !important
    color: var(--white) !important
