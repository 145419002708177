.tableContainer
  overflow: auto
  position: relative
  border: 1px solid rgba(0,0,0,.3)
  border-radius: 10px
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)
  &.tableBranch
    font-size: 14px
    margin-top: 10px

.principalTable
  background-color: rgba(0,0,0,.03)

.table
  display: inline-block !important
  min-width: 100%

.row
  border: 1px solid #eee
  display: flex
  flex-wrap: nowrap

.row:nth-child(odd)
  background-color: rgba(0,0,0,.03)

.cell
  display: flex
  justify-content: center
  align-items: center
  padding: 8px
  text-align: center
  a
    color: #000000
    font-size: 14px

.tableHeader
  background: #eee !important
  font-weight: 600
  font-size: 1.1rem

.link
  cursor: pointer 
  color: #2064e3 !important
  &:focus
    outline: none
