.title
  font-size: 20px
  font-weight: 600
  padding: 15px

.container
  padding: 0px 15px 15px 15px

.text
  padding-top: 5px
  padding-bottom: 5px
  span
    font-weight: 600

.linkContainer
  padding: 15px 0px
  cursor: pointer
  text-decoration: underline
  color: blue
  margin-bottom: 10px
  margin-top: 10px

.link
  color: #039be5
  text-decoration: none
  cursor: pointer
  margin-right: 20px
  &:hover
    text-decoration: underline

.button
  font-size: 30px
  width: auto
  background-color: #002747 !important
  margin-right: 10px !important

.tableContainer
  background-color: #ffffff
  overflow: auto
  max-height: calc(100vh - 180px)
  position: relative

.indicatorTable
  display: inline-block !important
  min-width: 100%

.tableHeader
  box-sizing: border-box
  display: flex
  flex-wrap: nowrap
  position: relative

.headerItem
  flex: 1
  box-sizing: border-box
  padding: 20px
  color: rgb(158, 158, 158)
  min-width: 120px
  max-width: 120px
  font-size: 12px
  border-bottom: 1px solid rgb(224, 224, 224)
  &.fullwidth
    max-width: 100%
