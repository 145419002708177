.container
  &.phone
    .tableContainer
      overflow: hidden
      overflow-x: auto
    .inputContainer, .buttonContainer, .button
      width: 100%
    .searchContainer
      flex-direction: column
      row-gap: 16px

.tableContainer
  width: 100%

.searchContainer
  padding: 16px
  display: flex
  column-gap: 8px
.button
  background-color: #002747 !important
  height: 100% !important
  &.excel
    background-color: #7cb342 !important
    color: #000000 !important

.circularProgress
  color: #ffffff !important
  width: 20px !important
  height: 20px !important

.buttons, .dates
  display: flex
  column-gap: 8px
