.container
  padding: 20px

.button
  font-size: 16px
  font-weight: 500
  width: 25%
  margin-right: 10px !important
  background-color: #002747 !important
  color: #ffffff
  border-radius: 20px !important
  padding: 10px
  text-align: center
  cursor: pointer
  margin-top: 15px
  text-transform: capitalize !important

.title
  font-size: 20px
  font-weight: 600
  padding: 15px

.circularProgress
  color: #002747 !important
  width: 20px !important
  height: 20px !important
  padding: 15px
