@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

@font-face {
  font-family: 'MaurenRegular';
  src: url('fonts/MaurenRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'MaurenRegularItalic';
  src: url('fonts/MaurenRegularItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'MaurenMedium';
  src: url('fonts/MaurenMedium.otf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  /* __________________________________________ Colors */
  --white: #ffffff;
  --yellow: #f8e35e;
  --primary-colorblue: #002747;
  --primary-text-gray: #758893;
  --secondary-colordarkblue: #163254;
  --secondary-text-blue: #eff9ff;
  --primary-colorgold: #9b722a;
  --primary-colorgray: #f1f3f4;
  --color-disabled: #959495;
  --color-gray: #595959;
  --coloer-border-gray: #989898;
  --coloer-border-gray-lite: #cccccc;
  --color-invalid: #eb5d0b;
  --red-alert: #f53a1a;
  --chart-line-green: #58a300;
  --primary-background: #f7f7f7;
  --primary-border-color-gray: #c6cfd5;
  --border-color-gray-light: #e9f4fb;
  --cream-color-background: #f6efe6;
  --highlight-color: #27c0d7;
  --done-color: #008000;
  --warning-background: #f8edeb;
  --warning-text: #f53a1a;
  --row-table: #fcf6ed;
  --link-color: #1976d2;

  /* ___________________________________________ Fonts */
  --poppins: 'Poppins', sans-serif;
  --title-font: 'MaurenRegular';
  --title-font-medium: 'MaurenMedium';
  --subtitle-font: 'MaurenRegularItalic';

  /* ___________________________________________ Shadow */
  --card-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 2px 1px -1px rgb(0 0 0 / 12%);
}
