.tableRow
  box-sizing: border-box
  width: 100%
  display: flex
  flex-wrap: nowrap
  cursor: pointer
  &:hover
    background-color: rgb(245, 245, 245)
  &.selected
    background-color: #cccccc

.rowItem
  flex: 1
  box-sizing: border-box
  padding: 20px
  font-size: 13px
  border-bottom: 1px solid rgb(224, 224, 224)
  min-width: 120px
  max-width: 120px
  &.true
    max-width: 100% !important
