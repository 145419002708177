.container
  &.phone
    .tableContainer
      overflow: hidden
      overflow-x: auto
    .inputContainer
      width: 100%
    .searchContainer
      flex-direction: column
      row-gap: 16px

.tableContainer
  width: 100%

.searchContainer
  padding: 16px
  display: flex
  column-gap: 8px
.button
  background-color: #002747 !important
  height: 100% !important

.circularProgress
  color: #ffffff !important
  width: 20px !important
  height: 20px !important
