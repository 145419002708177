.tabs
  background-color: #ffffff !important

.tableContainer
  background-color: #ffffff
  overflow: auto
  max-height: calc(100vh - 180px)
  position: relative

.indicatorTable
  display: inline-block !important
  min-width: 100%

.tableHeader
  box-sizing: border-box
  display: flex
  flex-wrap: nowrap
  position: relative

.headerItem
  flex: 1
  box-sizing: border-box
  padding: 20px
  color: rgb(158, 158, 158)
  min-width: 120px
  max-width: 120px
  font-size: 12px
  border-bottom: 1px solid rgb(224, 224, 224)
  &.fullwidth
    max-width: 100%

.loadingContainer
  width: 100%
  box-sizing: border-box
  padding: 20px
  display: flex
  justify-content: center
