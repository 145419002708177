.container
  padding: 30px
  background: #f7f7f7
  border-radius: 20px
  &.phone 
    margin: 16px

.text
  color: #758893
  padding: 16px
  font-size: 15px
  font-weight: 600

.textAviso
  color: #758893
  padding: 16px
  font-size: 18px
  font-weight: 600

.text2
  color: #002747
  padding: 16px
  font-size: 15px
  font-weight: 600
  text-align: center

.instructions
  color: #758893
  padding: 16px
  margin-top: -10px
  font-size: 13px

.titleInstructions
  color: #758893
  font-weight: 700

.concept
  color: #758893
  padding: 16px
  text-align: center
  font-weight: 700

.button
  background-color: #002747 !important
  color: #ffffff !important
  border-radius: 190px !important
  text-transform: capitalize !important
  &:hover
    background-color: #9b722a !important
  &.true
    background-color: #959495 !important

.disabled
  background-color: #959495 !important
  &:hover
    background-color: #959495 !important
    color: #ffffff !important

.buttonContainer
  margin-top: 20px
  margin-bottom: 20px
  padding-left: 10px
  text-align: center

.inputSelect
  border: 1px solid #c6cfd5
  border-radius: 8px
  height: 38px
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.08)
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.08)
  font-size: 14px
  width: 100%
  padding: 8px
  color: #758893
  margin-left: 10px

.loadingText
  width: 99% !important
  height: 30px !important
  border-radius: 4px !important
  margin-bottom: 10px
  margin-left: 5px
  margin-top: 5px

.circularProgress
  color: #002747 !important

.aviso
  text-align: center
  align-content: center
