.container
    padding: 16px

.header
    margin-bottom: 16px

.tableContainder
    min-height: 300px
    min-width: 50%

.buttonContainer
    display: flex
    align-items: center

.circularProgres
    width: 20px !important
    height: 20px !important
    margin-left: 16px

.rowStyle
    background-color: red
