#OrderCard
  background-color: var(--primary-background)
  border-radius: 8px
  padding: 24px
  margin-bottom: 24px
  width: 100%

.header
  display: flex
  justify-content: space-between

.cardTitle
  color: var(--secondary-colordarkblue)
  font-weight: 700

.iconContainer
  display: flex
  align-items: center
  cursor: pointer
  span
    font-size: 14px
    padding-left: 2px

.icon
  color: var(--secondary-colordarkblue)

.gold
  color: var(--primary-colorgold)

.colorless
  color: var(--primary-background)
