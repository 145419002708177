#InputFly
  border: 0
  margin: 0
  display: inline-flex
  padding: 0
  position: relative
  min-width: 0
  flex-direction: column
  vertical-align: top
  width: 100%
  box-sizing: border-box

.formLabel
  z-index: 1
  transform: translate(14px, 20px) scale(1)
  pointer-events: none
  top: 0
  left: 0
  position: absolute
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
  display: block
  transform-origin: top left
  color: rgba(0, 0, 0, 0.54)
  padding: 0
  font-size: 1rem
  font-family: "Roboto", "Helvetica", "Arial", sans-serif
  font-weight: 400
  line-height: 1
  letter-spacing: 0.00938em
  &.true
    transform: translate(14px, -6px) scale(0.75)

.FlyInputBase
  color: rgba(0, 0, 0, 0.87)
  cursor: text
  display: inline-flex
  position: relative
  font-size: 1rem
  box-sizing: border-box
  align-items: center
  font-family: "Roboto", "Helvetica", "Arial", sans-serif
  line-height: 1.1875em

.FlyFieldset
  border-color: #000000
  top: -5px
  left: 0
  right: 0
  bottom: 0
  margin: 0
  padding: 0
  position: absolute
  transition: padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
  border-style: solid
  border-width: 1px
  border-radius: 4px
  pointer-events: none

.FlyLegend
  padding: 0
  text-align: left
  transition: width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
  line-height: 11px
  padding: 0

.FlyInput
  font: inherit
  color: currentColor
  width: 100%
  border: 0
  height: 1.1875em
  margin: 0
  display: block
  padding: 18.5px 14px
  min-width: 0
  background: none
  box-sizing: content-box
  -webkit-tap-highlight-color: transparent
  &:focus
    outline: none
