$blueTMB: #002747
$TMBgold: #B4975A

#ChatContainer
  font-family: Roboto,sans-serif
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 9999
  display: flex
  justify-content: center
  align-items: center
  padding: 20px
  &.true
    .shadow
      opacity: 1
    .cardModule
      opacity: 1
      max-height: 700px
      height: calc(100vh - 40px)

.shadow
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba(0, 0, 0, 0.8)
  transition: opacity 200ms ease-out
  opacity: 0

.cardModule
  width: 100%
  max-width: 600px
  height: 0px
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms
  padding: 0px
  position: relative
  background-color: #fff
  border: 1px solid
  border-color: #e5e6e9 #dfe0e4 #d0d1d5
  border-radius: 3px
  opacity: 0
  box-sizing: border-box
  display: flex
  flex-direction: column

.closeContainer
  box-sizing: border-box
  padding: 10px
  text-align: right

.close
  cursor: pointer

.atentionDetails
  padding: 0px 15px 15px 15px
  box-sizing: border-box
  font-size: 14px
  flex: 1
  overflow-y: auto

.folio
  margin-bottom: 10px

.title
  color: $blueTMB
  margin-bottom: 10px

.itemText
  color: rgba(0, 0, 0, 0.6)
  margin-bottom: 10px

.controlPanel
  height: 80px
  padding: 5px 15px 5px 10px
  box-sizing: border-box
  display: flex

.item
  img
    width: 150px
    cursor: pointer

.textareaContainer
  width: 387px
  box-sizing: border-box

.textareaStyle
  width: 100%
  height: 100%
  box-sizing: border-box
  padding: 5px
  resize: none

.uploadContainer
  box-sizing: border-box
  flex: 1

.buttonsContainer
  box-sizing: border-box
  flex: 1

.button
  font-size: 14px !important
  background-color: $blueTMB !important
  padding: 10px 10px !important
  width: 100% !important
  height: 40% !important
  margin-top: 5px !important

.closeAtention
  background-color: #eeeeee !important
  color: #000000 !important
  font-size: 10px !important

.imageViewport
  width: 100%
  height: 100%
  border: 3px solid white
  box-sizing: border-box
  margin: 0 auto
  text-align: center
  position: relative
  overflow: hidden
  background-color: white
  box-shadow: inset rgba(0,0,0,0.15686) 0px 0px 4px
  input
    position: absolute
    z-index: 1
    width: 100%
    height: 100%
    top: 0
    left: 0
    opacity: 0
    cursor: pointer
  img
    width: 100%
    height: 100%
    object-fit: contain
    background-color: white
    border: none
    box-shadow: inset rgba(0,0,0,0.15686) 0px 0px 4px
  .imageIcon
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    color: $TMBgold
    i
      font-size: 35px

.productList
  display: flex
  flex-direction: column
  gap: 8px
