.tableRow
  box-sizing: border-box
  width: 100%
  display: flex
  cursor: pointer
  justify-content: center
  align-items: center
  border-bottom: 1px solid rgb(224, 224, 224)
  &:hover
    background-color: rgb(245, 245, 245)
  &.true
    background-color: rgb(245, 245, 245)

.rowItem
  box-sizing: border-box
  padding: 15px 8px
  font-size: 13px
  flex: 1
  text-align: center

.isHeader
  font-weight: 600
