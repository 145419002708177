$blueTMB: #002747
$blueLightTMB: #344f73
$blueDarkTMB: #000020
$blueDarkTMBHover: #010145
$goldTMB: #9b722a
$gray: #f5f5f5
$border: #404854

#Menu
  box-sizing: border-box
  width: 350px
  color: #ffffff
  &.desktop, &.tablet, &.phone
    position: fixed
    top: 0
    height: 100vh
    width: 0%
  &.open
    z-index: 2
    width: 100%
  .menuContainer
    width: 250px
    left: -250px
    transition: left 300ms ease-out
    &.true
      left: 0px

.overlay
  background-color: #000000
  opacity: 0
  width: 100%
  height: 100%
  position: fixed
  top: 0
  left: 0
  transition: opacity 800ms ease-out
  z-index: 3
  &.true
    opacity: 0.5

.menuContainer
  box-sizing: border-box
  color: #ffffff
  width: 350px
  background-color: $blueDarkTMB
  z-index: 3
  position: relative
  &.desktop, &.tablet, &.phone
    position: fixed
    height: 100vh
    width: 250px
    left: -250px
    overflow-y: scroll
    transition: left 300ms ease-out
    &.true
      left: 0px

.header
  box-sizing: border-box
  background-color: $blueTMB
  display: flex
  justify-content: center
  align-items: center
  padding: 16px
  height: 80px
  font-size: 1.5rem
  border-bottom: 1px solid $border

.itemsContainer
  border-bottom: 1px solid $border
  padding-bottom: 20px

.title
  color: #ffffff
  font-size: 1.2rem
  padding: 10px

.menuItem
  box-sizing: border-box
  padding: 10px
  color: $goldTMB
  text-decoration: none
  display: flex
  align-items: center
  &:hover
    background-color: $blueLightTMB
    color: #ffffff
  i
    padding-right: 10px
  &.true
    color: #ffffff
    
.closeSession
  display: flex
  align-items: center
  padding: 10px
  color: rgba(255, 255, 255, 0.7)
  cursor: pointer
  i
    padding-right: 10px
  &:hover
    color: rgba(255, 255, 255, 1)
