.container
  overscroll-behavior-y: contain

.miniMenu
  display: flex
  flex-direction: 'row'
  column-gap: 16px
  padding: 16px

.miniMenuOption
  cursor: pointer
  text-decoration: underline
  color: #4285F4
