.iballoon
  display: flex
  justify-content: flex-end
  margin: 4px
  .chatText
    max-width: 55%
    background-color: #143d8d
    color: #ffffff
    padding: 5px 8px 6px
    border-radius: 10px
    font-size: 14px
    font-weight: 300

.date
  font-size: 13px
  font-family: "Arial", Helvetica, sans-serif

.youballoon
  display: flex
  margin: 4px
  .chatText
    max-width: 55%
    background-color: #f1f0f0
    color: #4b4f56
    padding: 5px 8px 6px
    border-radius: 10px
    font-size: 15px
    font-weight: 300

.ballonImage
  width: 100%
  max-width: 200px
