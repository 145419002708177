.container
    padding: 16px

.header
    margin-bottom: 16px

.tableContainder
    min-height: 300px
    min-width: 50%

.progressContent
    box-sizing: border-box
    padding: 16px
    display: flex
    width: 100%
    align-items: center

.linearContainer
    flex: 1

.percentage
    padding-left: 16px
    width: 40px
    text-align: right

.buttonContainer
    display: flex
    align-items: center

.errorsList
    color: var(--red-alert)

.circularProgres
    width: 20px !important
    height: 20px !important
    margin-left: 16px

.secondaryMenu
    display: flex
    column-gap: 16px
    padding: 16px

.secondaryLink
    background-color: #eee
    padding: 8px
    text-decoration: none
    border-radius: 8px
    color: #000
    font-size: 14px
    transition: all 200ms ease-out
    &:hover
        background-color: #ccc

.active
    text-decoration: underline

.tableLink
    color: var(--link-color)

.sentFinished
    width: 100%
    padding: 8px
    color: var(--done-color)
    text-align: center

.payQuickerStatus
    color: red
