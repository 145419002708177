.tableHeader
  box-sizing: border-box
  width: 100%
  display: flex
  flex-wrap: nowrap

.headerItem
  flex: 1
  box-sizing: border-box
  padding: 15px 24px
  color: rgb(158, 158, 158)
  font-size: 12px
  border-bottom: 1px solid rgb(224, 224, 224)
