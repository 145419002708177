$blueTMB: #002747

.container
  width: 100%
  height: auto
  min-height: 100vh
  background-color: #cccccc
  display: flex
  flex-wrap: nowrap
  box-sizing: border-box
  &.tablet, &.phone
    .left
      width: 100%

.left
  width: 35%
  background-color: #ffffff
  box-sizing: border-box
  display: flex
  flex-direction: column
  align-items: center
  padding: 40px

.right
  width: 65%
  box-sizing: border-box
  background-size: cover
  background-repeat: no-repeat
  background-position: center center
  display: flex
  justify-content: center
  align-items: flex-end
  img
    width: 300px

.logo
  width: 200px

.title
  color: $blueTMB
  font-weight: 500

.formContainer
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  box-sizing: border-box
  padding-bottom: 40px

.inputContainer
  margin-bottom: 20px
  width: 100%

.button
  font-size: 30px
  width: 100%
  background-color: $blueTMB !important
  padding: 15px 10px !important

.noAccount
  margin-top: 10px
  color: #474747

.link
  text-decoration: none
  color: #007c89
  padding-left: 5px
  &:hover
    text-decoration: underline

.adminCheck
  width: 100%
  margin-bottom: 20px
  display: flex
  align-items: center
  span
    padding: 0 !important
  .checkText
    padding-left: 10px !important
    color: #474747

.error
  padding: 10px
  color: red
