$blueTMB: #002747

#Search
  box-sizing: border-box
  padding: 10px
  &.phone
    .inputsContainer
      flex-direction: column
      padding-bottom: 15px

.inputsContainer
  display: flex
  align-items: center
  margin-top: 10px

.dateContent
  padding-bottom: 10px

.inputContent
  width: 100%
  box-sizing: border-box
  padding: 5px

.inputContentDir
  width: 100%
  box-sizing: border-box
  padding: 5px
  margin-top: -10px


.buttonsContainer
  display: flex
  padding-left: 5px
  margin-bottom: 10px

.button
  margin-right: 10px !important
  background-color: $blueTMB !important
  &.clean
    background-color: #eeeeee !important
    color: #000000 !important
  &.excel
    background-color: #7cb342 !important
    color: #000000 !important

.buttonDisable
  margin-right: 10px !important
  background-color: #e5dfdf !important
  &.clean
    background-color: #eeeeee !important
    color: #000000 !important
  &.excel
    background-color: #7cb342 !important
    color: #000000 !important

.kitNoFound
  margin-top: 20px
  display: none

.kitFound
  margin-top: 20px
  display: block

.label
  font-weight: 700
  padding-right: 5px
  color: $blueTMB

.noValidate
  color: #c50a0aef
  font-weight: 600 

.validate
  color: #0ac50def
  font-weight: 600 

.select
  width: 100%
  padding: 19px
  border-radius: 5px
  font-size: 16px
